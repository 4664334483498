'use client'

import React, { useState, useEffect, useMemo } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import { Button } from "@/components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { ArrowLeft } from 'lucide-react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore'
import { firebaseConfig } from '../utils/firebase.config'
import AlgosLogo from '../images/Algos circle logo.png'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function ResultsPage() {
  const [selectedSport, setSelectedSport] = useState("MLB")
  const [selectedBetType, setSelectedBetType] = useState("moneyline")
  const [resultsData, setResultsData] = useState([])
  const [lastUpdateDate, setLastUpdateDate] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchResults = async () => {
      setIsLoading(true)
      const resultsRef = collection(db, `${selectedSport==='CBB' ? '' : selectedSport.toLowerCase()}records`)
      const querySnapshot = query(resultsRef, orderBy("date", "desc"), limit(1))
      const docSnapshot = await getDocs(querySnapshot)
      const resultsData = docSnapshot.docs[0]?.data() || {}
      
      // const betTypeKey = (selectedSport === 'MLB' || selectedSport === 'NHL') && selectedBetType === 'spread' ? 'moneyline' : selectedBetType
      const formattedResults = Object.entries(resultsData).map(([key, value]) => ({
        ...value
      }))

      setResultsData(formattedResults)
      setLastUpdateDate(resultsData.date || null)
      setIsLoading(false)
    }

    fetchResults()

    if (selectedSport === 'MLB' || selectedSport === 'NHL') {
      setSelectedBetType('moneyline')
    } else {
      setSelectedBetType('spread')
    }
  }, [selectedSport])

  const sports = ["MLB", "CBB", "NBA", "CFB", "NFL", "NHL"]

  const renderBetTypeButtons = () => {
    const buttonStyle = (betType) => `w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black ${
      selectedBetType === betType
        ? 'text-white bg-blue-600 hover:bg-blue-700'
        : 'text-black bg-white hover:bg-white/80'
    }`

    if (selectedSport === 'MLB' || selectedSport === 'NHL') {
      return (
        <>
          <Button 
            onClick={() => setSelectedBetType('moneyline')}
            className={buttonStyle('moneyline')}
          >
            Moneyline
          </Button>
          <Button 
            onClick={() => setSelectedBetType('overUnder')}
            className={buttonStyle('overUnder')}
          >
            Over/Under
          </Button>
        </>
      )
    } else {
      return (
        <>
          <Button 
            onClick={() => setSelectedBetType('spread')}
            className={buttonStyle('spread')}
          >
            Spread
          </Button>
          <Button 
            onClick={() => setSelectedBetType('overUnder')}
            className={buttonStyle('overUnder')}
          >
            Over/Under
          </Button>
        </>
      )
    }
  }

  const formatDate = (timestamp) => {
    return timestamp ? new Date(timestamp * 1000).toLocaleDateString() : 'N/A'
  }

  const filteredResultsData = useMemo(() => {
    if ((selectedSport === 'MLB' || selectedSport === 'NHL') && selectedBetType === 'overUnder') {
      return resultsData.slice(0, 6)
    }
    return resultsData
  }, [resultsData, selectedSport, selectedBetType])

  const renderTableData = (row) => {
    if (selectedBetType === 'overUnder') {
      return (
        <>
          <TableCell>{row.totals_wins}</TableCell>
          <TableCell>{row.totals_losses}</TableCell>
          <TableCell>{row.totals_win_pct}</TableCell>
          <TableCell>{row['totals profit (units)'] > 0 ? '+' : ''}{row['totals profit (units)']}</TableCell>
        </>
      )
    } else {
      return (
        <>
          <TableCell>{row.wins}</TableCell>
          <TableCell>{row.losses}</TableCell>
          <TableCell>{row.win_pct}</TableCell>
          <TableCell>{row['profit (units)'] > 0 ? '+' : ''}{row['profit (units)']}</TableCell>
        </>
      )
    }
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <Link to="/">
            <Button variant="outline" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-white hover:bg-white/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
              <ArrowLeft className="h-4 w-4 md:mr-2" />
              <span className="hidden md:inline">Back to Home</span>
            </Button>
          </Link>
          <img src={AlgosLogo} alt="Logo" className="h-12 w-auto"></img>
          <span className="ml-2 text-2xl font-bold text-blue-800">Sports Algos</span>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
            <div className="flex items-center space-x-4">
              <span className="font-semibold">Choose sport:</span>
              <Select value={selectedSport} onValueChange={setSelectedSport}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a sport" />
                </SelectTrigger>
                <SelectContent>
                  {sports.map((sport) => (
                    <SelectItem key={sport} value={sport}>{sport}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="flex space-x-2">
              {renderBetTypeButtons()}
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6 overflow-x-auto">
            <h2 className="text-2xl font-bold mb-4">Updated through games on {formatDate(lastUpdateDate)}</h2>
            {isLoading ? (
              <div className="text-center py-4">Loading...</div>
            ) : filteredResultsData.length > 0 ? (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Grade</TableHead>
                    <TableHead>Wins</TableHead>
                    <TableHead>Losses</TableHead>
                    <TableHead>Percent</TableHead>
                    <TableHead>Results</TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredResultsData.slice(0,-1).map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <span className={`font-bold px-3 py-2 rounded ${
                          row.grade === 'A' ? 'bg-green-700 text-white' :
                          row.grade === 'B' ? 'bg-green-500 text-white' :
                          row.grade === 'C' ? 'bg-yellow-500 text-white' :
                          row.grade === 'D' ? 'bg-red-500 text-white' :
                          row.grade === 'F' ? 'bg-red-700 text-white' :
                          'bg-blue-500 text-white'
                        }`}>
                          {row.grade}
                        </span>
                      </TableCell>
                      {renderTableData(row)}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <div className="text-center py-4">No results available</div>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  )
}