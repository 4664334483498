import React, { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getSubscriptionStatus } from './subscriptionStatus';
import { firebaseConfig } from './firebase.config';

const app = initializeApp(firebaseConfig);

const ProtectedRoute = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const auth = getAuth(app);

  useEffect(() => {
    const checkAuthAndSubscription = async (user) => {
      if (user) {
        setIsAuthenticated(true);
        try {
          const subscriptionStatus = await getSubscriptionStatus(app);
          setIsSubscribed(subscriptionStatus);
        } catch (error) {
          console.error("Error checking subscription status:", error);
          setIsSubscribed(false);
        }
      } else {
        setIsAuthenticated(false);
        setIsSubscribed(false);
      }
      setIsLoading(false);
    };

    const unsubscribe = onAuthStateChanged(auth, checkAuthAndSubscription);

    return () => unsubscribe();
  }, [auth]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (!isSubscribed) {
    return <Navigate to="/subscribe" state={{ from: location }} replace />;
  }

  return children;
};

export default ProtectedRoute;