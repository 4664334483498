'use client'

import React, { useState, useEffect, useCallback } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { Input } from "@/components/ui/input"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { ArrowLeft, ArrowUp, ArrowDown, ArrowUpDown, Info, HelpCircle } from 'lucide-react'
import { initializeApp } from 'firebase/app'
import { getFirestore, collection, query, orderBy, limit, getDocs } from 'firebase/firestore'
import { firebaseConfig } from '../utils/firebase.config'
import AlgosLogo from '../images/Algos circle logo.png'

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const PredictionsTable = () => {
  const [selectedSport, setSelectedSport] = useState("MLB")
  const [predictionsCache, setPredictionsCache] = useState({})
  const [filteredPredictions, setFilteredPredictions] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [sortColumn, setSortColumn] = useState("")
  const [sortDirection, setSortDirection] = useState("asc")
  const [isLoading, setIsLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)

  const fetchPredictions = useCallback(async (sport) => {
    setIsLoading(true)
    try {
      // console.log(`${sport==='CBB' ? '' : sport.toLowerCase()}picks`)
      const predictionsRef = collection(db, `${sport==='CBB' ? '' : sport.toLowerCase()}picks`)
      const querySnapshot = query(predictionsRef, orderBy("date", "desc"), limit(1))
      const docSnapshot = await getDocs(querySnapshot)
      const predictionsData = docSnapshot.docs[0]?.data() || {}
      const formattedPredictions = Object.entries(predictionsData)
        // .filter(([key]) => key !== 'date') // Exclude the 'date' entry
        .map(([key, value]) => ({
          ...value,
          // id: key // Use the key as an id for each prediction
          date: value
        }))
      console.log(`Fetched ${formattedPredictions.length} predictions for ${sport}`)
      console.log(formattedPredictions)
      return formattedPredictions
    } catch (error) {
      console.error(`Error fetching predictions for ${sport}:`, error)
      return []
    } finally {
      setIsLoading(false)
    }
  }, [])

  useEffect(() => {
    if (!predictionsCache[selectedSport]) {
      fetchPredictions(selectedSport).then(newPredictions => {
        setPredictionsCache(prevCache => ({
          ...prevCache,
          [selectedSport]: newPredictions
        }))
      })
    } else {
      setIsLoading(false) // If we're using cached data, we're not loading
    }
  }, [selectedSport, fetchPredictions, predictionsCache])

  useEffect(() => {
    const currentPredictions = predictionsCache[selectedSport] || []
    if (currentPredictions.length > 0) {
      const filtered = currentPredictions.filter(prediction => {
        const homeTeam = getTeamName(prediction, true)
        const awayTeam = getTeamName(prediction, false)
        return (homeTeam && homeTeam.toLowerCase().includes(searchTerm.toLowerCase())) ||
               (awayTeam && awayTeam.toLowerCase().includes(searchTerm.toLowerCase()))
      })
      
      if (sortColumn) {
        filtered.sort((a, b) => {
          if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1
          if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1
          return 0
        })
      }
      
      setFilteredPredictions(filtered)
    } else {
      setFilteredPredictions([])
    }
  }, [predictionsCache, selectedSport, searchTerm, sortColumn, sortDirection])

  const sports = ["CBB", "NBA", "MLB", "CFB", "NFL", "NHL"]

  const handleSort = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc")
    } else {
      setSortColumn(column)
      setSortDirection("asc")
    }
  }

  const renderSortIcon = (column) => {
    if (sortColumn !== column) {
      return <ArrowUpDown className="ml-2 h-4 w-4 inline" />
    }
    return sortDirection === "asc" ? 
      <ArrowUp className="ml-2 h-4 w-4 inline" /> : 
      <ArrowDown className="ml-2 h-4 w-4 inline" />
  }

  const formatDate = (timestamp) => {
    return new Date(timestamp * 1000).toLocaleDateString()
  }

  const getTeamName = (prediction, isHome) => {
    if (!prediction) return ''
    if (selectedSport === 'MLB') {
      return isHome ? prediction.home_team_stats_team : prediction.away_team_stats_team
    } else if (selectedSport === 'NHL') {
      return isHome ? prediction.home_team_stat_nst_stat_team : prediction.away_team_stat_nst_stat_team
    } else if (selectedSport === 'CBB') {
      return isHome ? prediction.home_team : prediction.away_team
    } else {
      return isHome ? prediction.home_team_team_stat_team : prediction.away_team_team_stat_team
    }
  }

  const getWinProbability = (prediction) => {
    if (!prediction) return 0
    if (selectedSport === 'MLB' || selectedSport === 'NHL') {
      return prediction.win_pct_proba
    }
    return prediction.predicted_home_win_pct
  }

  const getPicksColumnName = () => {
    return (selectedSport === 'MLB' || selectedSport === 'NHL') ? 'Moneyline Picks' : 'Spread Picks'
  }

  const getPicks = (prediction) => {
    if (!prediction) return ''
    if (selectedSport === 'MLB' || selectedSport === 'NHL') {
      return prediction.win_pct_picks
    }
    return prediction.spread_picks
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <Link to="/dashboard">
            <Button variant="outline" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-white hover:bg-white/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
              <ArrowLeft className="h-4 w-4 md:mr-2" />
              <span className="hidden md:inline">Back to Dashboard</span>
            </Button>
          </Link>
          <img src={AlgosLogo} alt="Logo" className="h-12 w-auto"></img>
          <span className="ml-2 text-2xl font-bold text-blue-800">Sports Algos</span>
        </div>

        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="bg-gradient-to-r from-blue-500 to-purple-600 rounded-lg shadow-lg p-4 sm:p-6 mb-8"
        >
          <div className="flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0">
            <div className="flex items-center mb-4 sm:mb-0">
              <Info className="text-white mr-4 h-6 w-6 sm:h-8 sm:w-8" />
              <h2 className="text-xl sm:text-2xl font-bold text-white">Unit Recommendations</h2>
            </div>
            <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4">
              <div className="bg-green-700 rounded-full px-4 py-2">
                <span className="text-white font-semibold text-sm sm:text-base">A: 2 Units</span>
              </div>
              <div className="bg-green-500 rounded-full px-4 py-2">
                <span className="text-white font-semibold text-sm sm:text-base">B: 1 Unit</span>
              </div>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex flex-col md:flex-row justify-between items-center mb-6 space-y-4 md:space-y-0">
            <div className="flex items-center space-x-4">
              <span className="font-semibold">Choose sport:</span>
              <Select value={selectedSport} onValueChange={setSelectedSport}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a sport" />
                </SelectTrigger>
                <SelectContent>
                  {sports.map((sport) => (
                    <SelectItem key={sport} value={sport}>{sport}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
              <DialogTrigger asChild>
                <Button
                  variant="outline"
                  size="icon"
                  className="border-0 h-8 w-8 rounded-full"
                  onClick={() => setIsOpen(true)}
                >
                  <HelpCircle className="" />
                  <span className="sr-only">Help</span>
                </Button>
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Understanding Our Predictions</DialogTitle>
                </DialogHeader>
                <div className="mt-4 text-sm text-gray-500">
                  <p>Our predictions are based on advanced statistical models and machine learning algorithms. Here's a brief overview:</p>
                  <ul className="list-disc list-inside mt-2 space-y-1">
                    <li>The grade (A, B, C, D, F) indicates the confidence level of our prediction.</li>
                    <li>We compare tens of models to each other to produce our confidence.</li>
                    <li>The closer the model's predictions are to each other, the better the grade.</li>
                    <li>We believe every play is the correct one -- some more confident than others.</li>
                    <li>Every season varies, so check the results page to see the historical records of each grade.</li>
                    <li>Unit recommendations are above, but you can also use the grades as guides if you're leaning a certain way.</li>
                  </ul>
                  <p className="mt-4">Always gamble responsibly and within your means.</p>
                </div>
              </DialogContent>
            </Dialog>
            <Input
              type="text"
              placeholder="Search teams..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="max-w-xs"
            />
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6 overflow-x-auto">
            {isLoading ? (
              <div className="text-center py-4">Loading...</div>
            ) : (
              <Table>
                <TableHeader>
                  <TableRow>
                    <TableHead>Home Team</TableHead>
                    <TableHead>Away Team</TableHead>
                    <TableHead>Home Win %</TableHead>
                    <TableHead>{getPicksColumnName()}</TableHead>
                    <TableHead onClick={() => handleSort("grade")} className="cursor-pointer">
                      Grade
                      {renderSortIcon("grade")}
                    </TableHead>
                    <TableHead>Totals %</TableHead>
                    <TableHead>Totals Picks</TableHead>
                    <TableHead onClick={() => handleSort("totals_grade")} className="cursor-pointer">
                      Totals Grade
                      {renderSortIcon("totals_grade")}
                    </TableHead>
                  </TableRow>
                </TableHeader>
                <TableBody>
                  {filteredPredictions.map((prediction, index) => (
                    <TableRow key={index}>
                      <TableCell>{getTeamName(prediction, true)}</TableCell>
                      <TableCell>{getTeamName(prediction, false)}</TableCell>
                      <TableCell>{(getWinProbability(prediction) * 100).toFixed(2)}%</TableCell>
                      <TableCell>{getPicks(prediction)}</TableCell>
                      <TableCell>
                        <span className={`font-bold px-3 py-2 rounded ${
                          prediction.grade === 'A' ? 'bg-green-700 text-white' :
                          prediction.grade === 'B' ? 'bg-green-500 text-white' :
                          prediction.grade === 'C' ? 'bg-yellow-500 text-white' :
                          prediction.grade === 'D' ? 'bg-red-500 text-white' :
                          prediction.grade === 'F' ? 'bg-red-700 text-white' :
                          'bg-blue-500 text-white'
                        }`}>
                          {prediction.grade}
                        </span>
                      </TableCell>
                      <TableCell>{(prediction.totals_proba * 100).toFixed(2)}%</TableCell>
                      <TableCell>{prediction.total_points_picks}</TableCell>
                      <TableCell>
                        <span className={`font-bold px-3 py-2 rounded ${
                          prediction.totals_grade === 'A' ? 'bg-green-700 text-white' :
                          prediction.totals_grade === 'B' ? 'bg-green-500 text-white' :
                          prediction.totals_grade === 'C' ? 'bg-yellow-500 text-white' :
                          prediction.totals_grade === 'D' ? 'bg-red-500 text-white' :
                          'bg-red-700 text-white'
                        }`}>
                          {prediction.totals_grade}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </motion.div>
      </div>
    </div>
  )
}

export default PredictionsTable