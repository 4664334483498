import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"
import { Menu, X, Check, Plus, Minus, Brain, BarChart3, Zap, Target, History, Users, Mail, LayoutDashboard, UserPlus, BarChart, Star, Quote } from 'lucide-react'
import { Card, CardContent } from "@/components/ui/card"

import AlgosLogo from '../images/Algos Logo.png'
import HeroImage from '../images/hero-image-v2.png'

const AnimatedSection = React.forwardRef(({ children, className, bgColor }, ref) => {
  const [isVisible, setIsVisible] = useState(false)
  const sectionRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true)
          observer.unobserve(entry.target)
        }
      },
      {
        threshold: 0.1
      }
    )

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current)
      }
    }
  }, [])

  return (
    <motion.div
      ref={(node) => {
        sectionRef.current = node;
        if (typeof ref === 'function') {
          ref(node);
        } else if (ref) {
          ref.current = node;
        }
      }}
      initial={{ opacity: 0, y: 50 }}
      animate={isVisible ? { opacity: 1, y: 0 } : {}}
      transition={{ duration: 0.8, ease: "easeOut" }}
      className={`${className} ${bgColor}`}
    >
      {children}
    </motion.div>
  )
})

AnimatedSection.displayName = 'AnimatedSection';

const ModernButton = ({ children, className, ...props }) => (
  <Link to={props.link}>
    <Button
      className={`relative overflow-hidden transition-all duration-300 ease-out ${className}`}
      {...props}
    >
      <span className="relative z-10">{children}</span>
      <motion.div
        className="absolute inset-0 bg-white bg-opacity-20"
        initial={{ scale: 0, opacity: 0 }}
        whileHover={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.3 }}
      />
    </Button>
  </Link>
)

const CustomAccordionTrigger = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <AccordionTrigger
    {...props}
    ref={forwardedRef}
    className={`group flex flex-1 items-center justify-between py-4 px-6 text-left text-lg font-semibold text-gray-900 hover:text-blue-600 transition-all duration-300 ${className}`}
  >
    {children}
    <Plus className="h-6 w-6 text-blue-600 shrink-0 transition-transform duration-300 group-data-[state=open]:hidden" />
    <Minus className="h-6 w-6 text-blue-600 shrink-0 transition-transform duration-300 hidden group-data-[state=open]:block" />
  </AccordionTrigger>
))

CustomAccordionTrigger.displayName = 'CustomAccordionTrigger'

const CustomAccordionContent = React.forwardRef(({ children, className, ...props }, forwardedRef) => (
  <AccordionContent
    {...props}
    ref={forwardedRef}
    className={`overflow-hidden text-gray-700 transition-all ${className}`}
  >
    <div className="pb-4 pt-0 px-6">
      <div className="bg-blue-50 p-4 rounded-lg border border-blue-100 shadow-inner">
        {children}
      </div>
    </div>
  </AccordionContent>
))

CustomAccordionContent.displayName = 'CustomAccordionContent'

export default function IndexPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [activeTestimonial, setActiveTestimonial] = useState(0)
  const [email, setEmail] = useState('')
  const [isEmailValid, setIsEmailValid] = useState(true)
  const navigate = useNavigate()
  const featuresRef = useRef(null);
  const howItWorksRef = useRef(null);
  const pricingRef = useRef(null);
  const testimonialsRef = useRef(null);
  const faqRef = useRef(null);
  const methodologyRef = useRef(null);

  const testimonials = [
    {
      name: "Sarah M.",
      role: "Sports Enthusiast",
      content: "As a casual sports fan, Sports Algos helps me make smarter decisions and enjoy the games even more."
    },
    {
      name: "Mike R.",
      role: "Fantasy Sports Player",
      content: "The data and analysis from Sports Algos give me a huge edge in my fantasy leagues!"
    },
    {
      name: "John S.",
      role: "Professional Bettor",
      content: "Sports Algos has revolutionized my betting strategy. The insights I get are invaluable."
    },
  ]

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTestimonial((prev) => (prev + 1) % testimonials.length)
    }, 5000)
    return () => clearInterval(interval)
  })

  const handleEmailSubmit = (e) => {
    e.preventDefault()
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    setIsEmailValid(isValid)
    if (isValid) {
      const encodedEmail = encodeURIComponent(email)
      navigate(`/register?email=${encodedEmail}`)
    }
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const handleScroll = (ref) => {
    toggleMenu();
    setTimeout(() => {
      const yOffset = isMenuOpen ? -240 : -70; // Adjust this value based on your navbar height
      const y = ref.current.getBoundingClientRect().top + window.scrollY + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
      // ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }, 100);
  };

  // New state for active sport in the Methodology section
  const [activeSport, setActiveSport] = useState('MLB')

  // Sports data for the Methodology section
  const sports = [
    {
      name: 'MLB',
      summary: 'At Sports Algos, our MLB model analyzes box score stats along with more advanced metrics like Isolated Power, WPA, aLI, and wRC+ to predict game outcomes with high accuracy. We focus on every aspect of the sport: batting, starting pitchers, relief pitchers, and defense. The hard part is analyzing what players/teams have been underperforming and/or overperforming to their expected performance so we can calculate an edge. This is where the public often struggles to find value, but our AI driven insights analyze hundreds of thousands of data points to calculate a "true" performance projection.'
    },
    {
      name: 'NBA',
      summary: 'At the center of our NBA model is PIE, a metric commonly used by the NBA to measure overall efficiency of players and teams as a whole. Another large part of our model deals with recent team performance. Basketball is a game of momentum and getting "hot" is a real thing. For that reason we weight more heavily weight recent games when running calculations. We don\'t care about a team\'s overall record, but rather their efficiency offensively and defensively. The strength of their opponent is also important in analyzing performance as a losing streak may not that bad when a team is playing the hardest teams in the leagues.'
    },
    {
      name: 'CBB',
      summary: 'College sports are significantly harder to analyze due to the spinning door of lineups (especially with the recent transfer portal) and the disparity between the best and worst teams. For those reasons we try to focus on stats that are not as volatile, such as shot distances and pace of play. Those stats are often better indicators of a team\'s success over such a wide range of opponent skill levels. Of course we also use the usual box score stats like FG% and a bit more advanced like TS% or eFG%, but give them a bit less weight. Turnovers and Rebounds are another massive part of college basketball and can easily influence momentum which is why we pay close attention to them and believe in the idea of "heat checks" for teams on a roll.'
    },
    {
      name: 'NHL',
      summary: 'This is one of our harder sports to analyze with most advanced stats being fairly correlated with each other, giving us less attributes to work with. We include Corsi, Fenwick, and PDO metrics that all deal with shooting stats. They help to convey what teams are "lucky" to tell us if they will eventually regress back to the mean. Corsi and Fenwick are nearly identical except Fenwick does not include blocked shots in its calculations. We use these stats for each level of strength played throughout the game (5v5, Power Play, Penalty Kill, 4v4, etc.).'
    },
    {
      name: 'NFL',
      summary: 'NFL models are focused on advanced data involving the team\'s efficiency in game situations. We analyze their performances on each type of down and calculate likelihoods of scoring chances based off of their recent performances. There are a variety of advanced stats we use since there are so many aspects of the game (throwing, running, reciving, special teams, defense). We merge them together and formulate a single percentage for you.'
    },
    {
      name: 'CFB',
      summary: 'For our CFB models, we focus on team level data over individual players because of the variability of college sports and continuous lineup changes. Teams are often able to adjust to the change of players and collectively make up the stats as predicted. We focus on stats for each down and location on the field to give a precise probability of the likelihood of scoring chances on each drive. This allows us to more accurately anticipate team scores and the situational aspect of the game.'
    }
  ]

  return (
    <motion.div className="min-h-screen">
      {/* Navbar */}
      <nav className="sticky top-0 z-50 bg-white bg-opacity-90 backdrop-filter backdrop-blur-lg shadow-md">
        <div className="container mx-auto px-6 py-3">
          <div className="flex justify-between items-center">
            <motion.div 
              className="flex items-center space-x-2"
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.5 }}
            >
              {/* <span className="text-xl font-bold text-indigo-700">Sports</span> */}
              <img src={AlgosLogo} alt="Logo" className="h-9 w-auto mr-4"></img>
            </motion.div>
            <div className="hidden md:flex items-center space-x-6">
              {['Features', 'How It Works', 'Methodology', 'Pricing', 'Testimonials', 'FAQ'].map((item, index) => (
                <motion.a
                  key={item}
                  href={`#${item.toLowerCase().replace(' ', '-')}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll(
                      item === 'Features' ? featuresRef :
                      item === 'How It Works' ? howItWorksRef :
                      item === 'Methodology' ? methodologyRef :
                      item === 'Pricing' ? pricingRef :
                      item === 'Testimonials' ? testimonialsRef :
                      faqRef
                    );
                  }}
                  className="text-gray-600 hover:text-blue-600 transition duration-300"
                  initial={{ opacity: 0, y: -20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  {item}
                </motion.a>
              ))}
              <motion.div
                className="text-blue-600 hover:text-blue-600 transition duration-300"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <Link to='/results'>Results</Link>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                <ModernButton variant="outline" link="/login" className="text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white">Log In</ModernButton>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
              >
                <ModernButton link="/register" className="bg-blue-600 text-white hover:bg-blue-700">Subscribe Now</ModernButton>
              </motion.div>
            </div>
            <div className="md:hidden">
              <ModernButton variant="ghost" size="icon" onClick={toggleMenu}>
                {isMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </ModernButton>
            </div>
          </div>
        </div>
        <AnimatePresence>
          {isMenuOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="md:hidden bg-white px-4 py-2 shadow-lg overflow-hidden"
            >
              {['Features', 'How It Works', 'Methodology', 'Pricing', 'Testimonials', 'FAQ'].map((item) => (
                <a
                  key={item}
                  href={`#${item.toLowerCase().replace(' ', '-')}`}
                  onClick={(e) => {
                    e.preventDefault();
                    handleScroll(
                      item === 'Features' ? featuresRef :
                      item === 'How It Works' ? howItWorksRef :
                      item === 'Methodology' ? methodologyRef :
                      item === 'Pricing' ? pricingRef :
                      item === 'Testimonials' ? testimonialsRef :
                      faqRef
                    );
                  }}
                  className="block py-2 text-gray-600 hover:text-blue-600"
                >
                  {item}
                </a>
              ))}
              <Link to='/results' className="block py-2 text-blue-600 hover:text-blue-600">Results</Link>
              <ModernButton link="/login" variant="outline" className="w-full mt-2 text-blue-600 border-blue-600 hover:bg-blue-600 hover:text-white">Log In</ModernButton>
              <ModernButton link="/register" className="w-full mt-2 bg-blue-600 text-white hover:bg-blue-700">Subscribe Now</ModernButton>
            </motion.div>
          )}
        </AnimatePresence>
      </nav>

      {/* Hero Section */}
      <AnimatedSection className="relative py-20" id="top" bgColor="bg-white">
        <div className="container mx-auto px-8">
          <div className="flex flex-col items-center text-center">
            <motion.div 
              className="mb-10"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <h1 className="text-2xl md:text-3xl font-bold mb-4 leading-tight text-blue-600">
                Sports Algos
              </h1>
              <h2 className="text-4xl md:text-5xl font-bold mb-4 leading-tight text-black">
                Unlock the Power of <span className="text-transparent bg-gradient-to-r from-blue-300 via-blue-600 to-black bg-clip-text">Sports Analytics</span>
              </h2>
              <p className="text-xl mb-6 text-gray-500 max-w-2xl mx-auto">
                Get expert insights, advanced algorithms, and data-driven predictions to elevate your sports game.
              </p>
              <Button size="lg" className="bg-gradient-to-r from-blue-300 to-blue-600 text-white hover:opacity-90 transition-opacity shadow-lg">
                <Link to="/register">
                  Get Started Now
                </Link>
              </Button>
            </motion.div>
            <motion.div 
              className="w-full max-w-2xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
            >
              <img src={HeroImage} alt="Logo" className="w-full h-96 bg-blue-100 rounded-lg shadow-lg"></img>
            </motion.div>
          </div>
        </div>
      </AnimatedSection>

      {/* Features Section */}
      <AnimatedSection className="py-20" id="features" ref={featuresRef} bgColor="bg-gradient-to-b from-white to-blue-100">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">Why Choose Sports Algos?</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              { title: "Advanced Algorithms", description: "Utilize cutting-edge machine learning models for accurate predictions", icon: Brain },
              { title: "Comprehensive Data Analysis", description: "Access in-depth statistics and trends across multiple sports", icon: BarChart3 },
              { title: "Lightning-Fast Insights", description: "Get quick, actionable insights to inform your decisions", icon: Zap },
              { title: "Multi-Sport Coverage", description: "Enjoy comprehensive analysis across a wide range of popular sports", icon: Target },
              { title: "Trend Identification", description: "Spot emerging patterns and trends before they become mainstream", icon: History },
              { title: "Educational Resources", description: "Access a wealth of educational content to improve your sports analytics skills", icon: Users }
            ].map((feature, index) => (
              <motion.div 
                key={index} 
                className="bg-white rounded-lg shadow-lg p-6 hover:shadow-xl transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.2, delay: index * 0.1 }}
              >
                <div className="flex items-center mb-4">
                  <feature.icon className="w-8 h-8 text-blue-600 mr-3" />
                  <h3 className="text-xl font-semibold text-blue-600">{feature.title}</h3>
                </div>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>
      </AnimatedSection>

      {/* How It Works Section */}
      <AnimatedSection className="py-20" id="how-it-works" ref={howItWorksRef} bgColor="bg-gradient-to-b from-blue-100 to-white">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">How It Works</h2>
          <div className="max-w-3xl mx-auto">
            <div className="absolute left-1/2 top-32 bottom-10 w-px border-l-2 border-dashed border-blue-300 -translate-x-1/2 z-0" />

            {[{ icon: UserPlus, title: "Create an Account", description: "Sign up quickly and easily to get started with Sports Algos." },
              { icon: LayoutDashboard, title: "Access Analytics Dashboard", description: "Dive into our comprehensive dashboard of sports analytics." },
              { icon: Brain, title: "Review AI Predictions", description: "Explore AI-powered predictions and use our advanced tools." },
              { icon: Mail, title: "Receive Daily Updates", description: "Get daily notifications for the latest sports updates and insights." },
              { icon: BarChart, title: "Make Smarter Decisions", description: "Use data-driven insights to make informed betting decisions." }
            ].map((step, index) => (
              <motion.div 
                key={index}
                className={`flex flex-col md:flex-row items-center mb-12 last:mb-0 ${
                  index % 2 === 0 ? 'md:flex-row-reverse' : ''
                }`}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <div className="flex-1 w-full md:w-1/2">
                  <div className={`bg-white rounded-lg shadow-lg p-6 ${
                    index % 2 === 0 ? 'md:ml-8' : 'md:mr-8'
                  }`}>
                    <h3 className="text-xl font-semibold mb-2 text-blue-600">{step.title}</h3>
                    <p className="text-gray-600">{step.description}</p>
                  </div>
                </div>
                <div className="flex-shrink-0 z-10">
                  <div className="bg-blue-600 text-white rounded-full w-16 h-16 flex items-center justify-center shadow-lg">
                    <step.icon size={32} />
                  </div>
                </div>
                <div className="flex-1 w-full md:w-1/2"></div>
              </motion.div>
            ))}
          </div>
        </div>
      </AnimatedSection>

      {/* Methodology Section (New) */}
      <AnimatedSection className="py-20" id="methodology" ref={methodologyRef} bgColor="bg-gradient-to-b from-white to-blue-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-bold text-center mb-2">Methodology</h2>
          <h3 className="text-xl text-center mb-6 text-gray-600">The Method to our Madness</h3>
          
          <div className="flex flex-wrap justify-center gap-2 mb-6">
            {sports.map((sport) => (
              <ModernButton
                key={sport.name}
                // variant={activeSport === sport.name ? "default" : "outline"}
                onClick={() => setActiveSport(sport.name)}
                className={activeSport === sport.name ? "text-sm md:text-base text-white bg-blue-600" : "hover:text-white hover:bg-blue-600"}
              >
                {sport.name}
              </ModernButton>
            ))}
          </div>

          <Card className="mt-6">
            <CardContent className="p-6">
              {sports.map((sport) => (
                sport.name === activeSport && (
                  <p key={sport.name} className="flex flex-wrap justify-center text-gray-700 leading-relaxed">
                    {sport.summary}
                  </p>
                )
              ))}
            </CardContent>
          </Card>
        </div>
      </AnimatedSection>

      {/* Pricing Section */}
      <AnimatedSection className="py-20" id="pricing" ref={pricingRef} bgColor="bg-gradient-to-b from-blue-100 to-white">
        <div className="container mx-auto max-w-6xl">
          <h2 className="text-4xl font-extrabold text-center mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
            Choose Your Plan
          </h2>
          <p className="text-xl text-center text-gray-600 mb-12">
            Simple, transparent pricing.
          </p>
          <div className="flex flex-wrap justify-center gap-8">
            {/* Monthly Plan */}
            <motion.div
              className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100 flex flex-col justify-between"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{ y: -5 }}
            >
              <div>
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-2xl font-bold text-gray-900">Monthly</h3>
                  <Zap className="w-8 h-8 text-blue-500" />
                </div>
                <p className="text-5xl font-bold text-blue-600 mb-14">
                  $30<span className="text-xl font-normal text-gray-600">/mo</span>
                </p>
                <ul className="space-y-3 mb-6">
                  <li className="flex items-center text-gray-600">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Full access to all features
                  </li>
                  <li className="flex items-center text-gray-600">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Daily updates and alerts
                  </li>
                  <li className="flex items-center text-gray-600">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Cancel anytime
                  </li>
                </ul>
              </div>
              <ModernButton link='/register' className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:opacity-90 transition-opacity">
                Get Started
              </ModernButton>
            </motion.div>

            {/* Annual Plan (Popular) */}
            <motion.div
              className="bg-white rounded-2xl shadow-xl p-8 border-2 border-purple-500 flex flex-col justify-between lg:scale-105"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              whileHover={{ y: -5 }}
            >
              <div className="absolute top-0 right-0 bg-purple-500 text-white px-3 py-1 rounded-bl-lg rounded-tr-lg text-sm font-semibold">
                Popular
              </div>
              <div>
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-2xl font-bold text-gray-900">Annual</h3>
                  <Star className="w-8 h-8 text-purple-500" />
                </div>
                <p className="text-5xl font-bold text-blue-600 mb-2">
                  $325<span className="text-xl font-normal text-gray-600">/yr</span>
                </p>
                <p className="text-green-500 font-semibold mb-6">Save $35 (Over 1 month free!)</p>
                <ul className="space-y-3 mb-6">
                  <li className="flex items-center text-gray-600">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    All features of Monthly Plan
                  </li>
                  <li className="flex items-center text-gray-600">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Priority customer support
                  </li>
                  <li className="flex items-center text-gray-600">
                    <Check className="w-5 h-5 mr-2 text-green-500" />
                    Discounted Price
                  </li>
                </ul>
              </div>
              <ModernButton link='/register' className="w-full bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:opacity-90 transition-opacity">
                Choose Annual
              </ModernButton>
            </motion.div>
          </div>
        </div>
      </AnimatedSection>

      {/* Testimonials Section */}
      <AnimatedSection className="py-20" id="testimonials" ref={testimonialsRef} bgColor="bg-gradient-to-b from-white to-blue-100">
        <div className="container mx-auto px-6 relative z-10">
          <h2 className="text-4xl font-bold text-center mb-12 text-gray-900">
            What Our Users Say
          </h2>
          <div className="max-w-4xl mx-auto">
            <AnimatePresence mode="wait">
              <motion.div
                key={activeTestimonial}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -50 }}
                transition={{ duration: 0.5 }}
                className="bg-white rounded-lg shadow-xl p-8 relative"
                style={{
                  background: "linear-gradient(white, white) padding-box, linear-gradient(to right, #3b82f6, #8b5cf6) border-box",
                  border: "3px solid transparent",
                }}
              >
                <Quote className="absolute top-4 left-4 text-blue-200 w-12 h-12 transform rotate-180" />
                <Quote className="absolute bottom-4 right-4 text-purple-200 w-12 h-12" />
                <div className="flex items-center mb-6 ml-10">
                  {/* <img
                    src={testimonials[activeTestimonial].avatar}
                    alt={testimonials[activeTestimonial].name}
                    className="w-16 h-16 rounded-full mr-4 object-cover border-2 border-blue-500"
                  /> */}
                  <div>
                    <h3 className="font-semibold text-xl text-gray-900">{testimonials[activeTestimonial].name}</h3>
                    <p className="text-blue-600">{testimonials[activeTestimonial].role}</p>
                  </div>
                </div>
                <p className="text-gray-600 text-lg italic mb-6 ml-10">"{testimonials[activeTestimonial].content}"</p>
                <div className="flex justify-center space-x-2">
                  {testimonials.map((_, index) => (
                    <button
                      key={index}
                      className={`w-3 h-3 rounded-full transition-all duration-300 ${
                        index === activeTestimonial
                          ? "bg-blue-600 w-6"
                          : "bg-gray-300 hover:bg-blue-400"
                      }`}
                      onClick={() => setActiveTestimonial(index)}
                    />
                  ))}
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </AnimatedSection>

      {/* FAQ Section */}
      <AnimatedSection className="py-20" id="faq" ref={faqRef} bgColor="bg-gradient-to-b from-blue-100 to-white">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center mb-12 text-gray-900">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto">
            <Accordion type="single" collapsible className="space-y-4">
              {[
                {
                  question: "What kind of sports betting analysis do you provide?",
                  answer: "We offer comprehensive analysis across major sports leagues, including CBB, NBA, NFL, CFB, MLB, and NHL. Our analyses include team performance metrics, historical data, and AI-driven predictions. With Sports Algos, you'll have access to in-depth insights that can give you a competitive edge in your sports endeavors."
                },
                {
                  question: "How accurate are your betting predictions?",
                  answer: "While no prediction is 100% accurate, our advanced algorithms and machine learning models significantly improve the likelihood of successful bets. We provide detailed analytics and confidence levels to help you make informed decisions. Our models are updated daily with the latest data and refined based on performance, ensuring you always have access to the most accurate predictions possible."
                },
                {
                  question: "What tools are included in your platform?",
                  answer: "Our platform includes a wide range of tools designed to enhance your sports betting experience:\n\n• AI-powered prediction models\n• Daily updates and alerts for optimal betting opportunities\n• Analytical visuals based on historical data points and user customization\n\nThese tools work together to provide you with a complete suite for making informed decisions and improving your overall sports knowledge."
                },
                {
                  question: "Is sports betting legal in my area?",
                  answer: "Sports betting laws vary by location. We recommend checking your local regulations before engaging in any betting activities. Our platform is designed for educational and entertainment purposes, and can be used for fantasy sports and other legal applications of sports analytics. We provide information and tools, but it's the user's responsibility to ensure compliance with local laws and regulations regarding sports betting."
                }
              ].map((item, index) => (
                <AccordionItem key={index} value={`item-${index}`} className="bg-white rounded-lg shadow-md overflow-hidden">
                  <CustomAccordionTrigger>{item.question}</CustomAccordionTrigger>
                  <CustomAccordionContent>
                    <p className="text-gray-700 leading-relaxed">{item.answer}</p>
                  </CustomAccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </AnimatedSection>

      {/* CTA Section */}
      <AnimatedSection className="py-20" bgColor="bg-gradient-to-b from-white to-blue-100">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-4 text-gray-900">Ready to Elevate Your Game?</h2>
          <p className="text-xl mb-8 text-gray-600">Join others who trust Sports Algos for expert analysis and winning strategies.</p>
          <form onSubmit={handleEmailSubmit} className="max-w-md mx-auto flex items-center">
            <Input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={`flex-grow mr-2 bg-white ${!isEmailValid ? 'border-red-500' : 'border-gray-300'}`}
            />
            <Button type="submit" size="lg" className="bg-blue-600 text-white hover:bg-blue-700">
              Join now!
            </Button>
          </form>
          {!isEmailValid && (
            <motion.p 
              className="text-red-500 text-sm mt-2"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            >
              Please enter a valid email address.
            </motion.p>
          )}
        </div>
      </AnimatedSection>

      {/* Footer */}
      <footer className="bg-gray-900 text-white py-10">
        <div className="container mx-auto px-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div>
              <h3 className="text-lg font-semibold mb-2">Sports Algos</h3>
              <p className="text-gray-400">Empowering sports fans with cutting-edge analytics and AI-driven insights.</p>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Quick Links</h3>
              <ul className="space-y-2">
                <li><a href="#top" onClick={(e) => { e.preventDefault(); window.scrollTo({ top: 0, behavior: 'smooth' }); }} className="text-gray-400 hover:text-white transition duration-300">Home</a></li>
                <li><a href="#features" onClick={(e) => { e.preventDefault(); handleScroll(featuresRef); }} className="text-gray-400 hover:text-white transition duration-300">Features</a></li>
                <li><a href="#how-it-works" onClick={(e) => { e.preventDefault(); handleScroll(howItWorksRef); }} className="text-gray-400 hover:text-white transition duration-300">How It Works</a></li>
                <li><a href="#methodology" onClick={(e) => { e.preventDefault(); handleScroll(methodologyRef); }} className="text-gray-400 hover:text-white transition duration-300">Methodology</a></li>
                <li><a href="#pricing" onClick={(e) => { e.preventDefault(); handleScroll(pricingRef); }} className="text-gray-400 hover:text-white transition duration-300">Pricing</a></li>
                <li><a href="#faq" onClick={(e) => { e.preventDefault(); handleScroll(faqRef); }} className="text-gray-400 hover:text-white transition duration-300">FAQ</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Legal</h3>
              <ul className="space-y-2">
                <li><a href="#top" className="text-gray-400 hover:text-white transition duration-300">Terms of Service</a></li>
                <li><a href="#top" className="text-gray-400 hover:text-white transition duration-300">Privacy Policy</a></li>
                <li><a href="https://www.ncpgambling.org/help-treatment/" target='_blank' rel="noreferrer" className="text-gray-400 hover:text-white transition duration-300">Responsible Gambling</a></li>
              </ul>
            </div>
            <div>
              <h3 className="text-lg font-semibold mb-2">Connect With Us</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/sportsalgos34" target='_blank' rel="noreferrer" className="text-gray-400 hover:text-white transition duration-300">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                  </svg>
                </a>
                <a href="https://www.instagram.com/sportsalgos34/" target='_blank' rel="noreferrer" className="text-gray-400 hover:text-white transition duration-300">
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path fillRule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clipRule="evenodd" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="mt-8 border-t border-gray-800 pt-8 text-center text-gray-400">
            <p>&copy; 2024 Sports Algos. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </motion.div>
  )
}