'use client'

import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom';
import { Button } from "@/components/ui/button"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select"
import { ArrowLeft } from 'lucide-react'
import AlgosLogo from '../images/Algos circle logo.png'

const sportApps = {
  NFL: "https://models-b5t5kdor8q5kpbmkrqie9g.streamlit.app/?embed=true",
  // NBA: "https://placeholder-nba-analysis.streamlit.app",
  MLB: "https://models-nqwojsvxamihjg3ds9g62p.streamlit.app/?embed=true",
  // NHL: "https://placeholder-nhl-analysis.streamlit.app",
  // CBB: "https://placeholder-cbb-analysis.streamlit.app",
  CFB: "https://models-jlshcb64h7nur85fq2a5tg.streamlit.app/?embed=True"
}

export default function AnalysisPage() {
  const [selectedSport, setSelectedSport] = useState("NFL")

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white p-8">
      <div className="max-w-7xl mx-auto">
        <div className="flex justify-between items-center mb-8">
          <Link to="/dashboard">
            <Button variant="outline" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-white hover:bg-white/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black">
              <ArrowLeft className="h-4 w-4 md:mr-2" />
              <span className="hidden md:inline">Back to Dashboard</span>
            </Button>
          </Link>
          <img src={AlgosLogo} alt="Logo" className="h-12 w-auto"></img>
          <span className="ml-2 text-2xl font-bold text-blue-800">Sports Algos</span>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="mb-6">
            <div className="flex items-center space-x-4">
              <span className="font-semibold">Choose sport:</span>
              <Select value={selectedSport} onValueChange={setSelectedSport}>
                <SelectTrigger className="w-[180px]">
                  <SelectValue placeholder="Select a sport" />
                </SelectTrigger>
                <SelectContent>
                  {Object.keys(sportApps).map((sport) => (
                    <SelectItem key={sport} value={sport}>{sport}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-lg p-6">
            <h2 className="text-2xl font-bold mb-4">{selectedSport} Statistical Analysis</h2>
            <div className="aspect-w-16 aspect-h-9">
              <iframe
                src={sportApps[selectedSport]}
                title={`${selectedSport} Statistical Analysis`}
                className="w-full h-[800px] border-0 rounded-lg"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  )
}