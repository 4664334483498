import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './utils/protected-route';

import IndexPage from './pages/index';
import RegisterPage from './pages/register';
import LoginPage from './pages/login';
import SubscribePage from './pages/subscribe';
import DashboardPage from './pages/dashboard';
import AnalysisPage from './pages/analysis';
import PredictionsPage from './pages/predictions';
import ResultsPage from './pages/results';


function App() {
  return (
    <Router>
       <Routes>
          <Route path="/" element={<IndexPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/subscribe" element={<SubscribePage />} />
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <DashboardPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/analysis" 
            element={
              <ProtectedRoute>
                <AnalysisPage />
              </ProtectedRoute>
            } 
          />
          <Route 
            path="/predictions" 
            element={
              <ProtectedRoute>
                <PredictionsPage />
              </ProtectedRoute>
            } 
          />
          <Route path="/results" element={<ResultsPage />} />
        </Routes>
    </Router>
  );
}

export default App;