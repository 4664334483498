'use client'

import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Button } from "@/components/ui/button"
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "@/components/ui/card"
import { Check, Star, Zap } from 'lucide-react'
import { getCheckoutUrl } from '../utils/processPayment'
import { initializeApp } from 'firebase/app'
import { firebaseConfig } from '../utils/firebase.config'
import { priceConfig } from '@/utils/price.config'
import AlgosLogo from '../images/Algos circle logo.png'

const app = initializeApp(firebaseConfig);

const subscriptionOptions = [
  {
    id: 'monthly',
    title: 'Monthly',
    price: '$30',
    period: 'month',
    features: [
      'Full access to all features',
      'Daily updates and alerts',
      'Cancel anytime',
    ],
    priceId: priceConfig.monthlyLive,
    icon: Zap,
  },
  {
    id: 'annual',
    title: 'Annual',
    price: '$325',
    period: 'year',
    features: [
      'All features of Monthly Plan',
      'Priority customer support',
      'Discounted Price',
    ],
    priceId: priceConfig.yearlyLive,
    icon: Star,
    popular: true,
  },
]

export default function SubscribePage() {
  const [selectedPlan, setSelectedPlan] = useState(subscriptionOptions[1].id)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubscribe = async () => {
    setIsLoading(true)
    const selectedOption = subscriptionOptions.find(option => option.id === selectedPlan)
    if (selectedOption) {
      try {
        const checkoutUrl = await getCheckoutUrl(app, selectedOption.priceId)
        window.location.href = checkoutUrl
      } catch (error) {
        console.error("Error getting checkout URL:", error)
        // Handle error (e.g., show error message to user)
      } finally {
        setIsLoading(false)
      }
    }
  }

  return (
    // {/* Pricing Section */}
    // <AnimatedSection className="py-20" id="pricing" ref={pricingRef} bgColor="bg-gradient-to-b from-blue-100 to-white">
    //   <div className="container mx-auto max-w-6xl">
    //     <h2 className="text-4xl font-extrabold text-center mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">
    //       Choose Your Perfect Plan
    //     </h2>
    //     <p className="text-xl text-center text-gray-600 mb-12">
    //       Simple, transparent pricing.
    //     </p>
    //     <div className="flex flex-wrap justify-center gap-8">
    //       {/* Monthly Plan */}
    //       <motion.div
    //         className="bg-white rounded-2xl shadow-xl p-8 border border-gray-100 flex flex-col justify-between"
    //         initial={{ opacity: 0, y: 20 }}
    //         animate={{ opacity: 1, y: 0 }}
    //         transition={{ duration: 0.5 }}
    //         whileHover={{ y: -5 }}
    //       >
    //         <div>
    //           <div className="flex items-center justify-between mb-4">
    //             <h3 className="text-2xl font-bold text-gray-900">Monthly</h3>
    //             <Zap className="w-8 h-8 text-blue-500" />
    //           </div>
    //           <p className="text-5xl font-bold text-blue-600 mb-6">
    //             $30<span className="text-xl font-normal text-gray-600">/mo</span>
    //           </p>
    //           <ul className="space-y-3 mb-6">
    //             <li className="flex items-center text-gray-600">
    //               <Check className="w-5 h-5 mr-2 text-green-500" />
    //               Full access to all features
    //             </li>
    //             <li className="flex items-center text-gray-600">
    //               <Check className="w-5 h-5 mr-2 text-green-500" />
    //               Daily updates and alerts
    //             </li>
    //             <li className="flex items-center text-gray-600">
    //               <Check className="w-5 h-5 mr-2 text-green-500" />
    //               Cancel anytime
    //             </li>
    //           </ul>
    //         </div>
    //         <ModernButton link='/register' className="w-full bg-gradient-to-r from-blue-600 to-purple-600 text-white hover:opacity-90 transition-opacity">
    //           Get Started
    //         </ModernButton>
    //       </motion.div>

    //       {/* Annual Plan (Popular) */}
    //       <motion.div
    //         className="bg-white rounded-2xl shadow-xl p-8 border-2 border-purple-500 flex flex-col justify-between lg:scale-105"
    //         initial={{ opacity: 0, y: 20 }}
    //         animate={{ opacity: 1, y: 0 }}
    //         transition={{ duration: 0.5, delay: 0.1 }}
    //         whileHover={{ y: -5 }}
    //       >
    //         <div className="absolute top-0 right-0 bg-purple-500 text-white px-3 py-1 rounded-bl-lg rounded-tr-lg text-sm font-semibold">
    //           Popular
    //         </div>
    //         <div>
    //           <div className="flex items-center justify-between mb-4">
    //             <h3 className="text-2xl font-bold text-gray-900">Annual</h3>
    //             <Star className="w-8 h-8 text-purple-500" />
    //           </div>
    //           <p className="text-5xl font-bold text-blue-600 mb-2">
    //             $325<span className="text-xl font-normal text-gray-600">/yr</span>
    //           </p>
    //           <p className="text-green-500 font-semibold mb-6">Save $35 (Over 1 month free!)</p>
    //           <ul className="space-y-3 mb-6">
    //             <li className="flex items-center text-gray-600">
    //               <Check className="w-5 h-5 mr-2 text-green-500" />
    //               All features of Monthly Plan
    //             </li>
    //             <li className="flex items-center text-gray-600">
    //               <Check className="w-5 h-5 mr-2 text-green-500" />
    //               Priority customer support
    //             </li>
    //             <li className="flex items-center text-gray-600">
    //               <Check className="w-5 h-5 mr-2 text-green-500" />
    //               Discounted Price
    //             </li>
    //           </ul>
    //         </div>
    //         <ModernButton link='/register' className="w-full bg-gradient-to-r from-purple-600 to-pink-600 text-white hover:opacity-90 transition-opacity">
    //           Choose Annual
    //         </ModernButton>
    //       </motion.div>
    //     </div>
    //   </div>
    // </AnimatedSection>

    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="flex items-center justify-center mb-6">
          <img src={AlgosLogo} alt="Logo" className="h-12 w-auto"></img>
          <span className="ml-2 text-2xl font-bold text-blue-800">Sports Algos</span>
        </div>
        <h2 className="text-4xl font-extrabold text-center mb-4 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600">Choose Your Subscription</h2>
        <p className="text-xl text-center text-gray-600">
          Select the plan that best fits your needs
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-2xl px-6">
        <motion.div
          className="bg-white py-8 px-4 shadow rounded-lg sm:px-10"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="grid gap-8 grid-cols-1 md:grid-cols-2">
            {subscriptionOptions.map((option) => (
              <Card 
                key={option.id} 
                className={`relative cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105 ${
                  selectedPlan === option.id 
                    ? 'border-blue-500 ring-2 ring-blue-500 shadow-lg' 
                    : 'border-gray-200 shadow-md hover:shadow-xl'
                } ${
                  option.id === 'annual'
                    ? 'border-purple-500'
                    : ''
                }`}
                onClick={() => setSelectedPlan(option.id)}
              >
                <CardHeader>
                  <CardTitle>{option.title}</CardTitle>
                  <CardDescription>
                    {option.popular && (
                      <div className="absolute top-0 right-0 bg-purple-500 text-white px-3 py-1 rounded-bl-lg rounded-tr-lg text-sm font-semibold">
                        Popular
                      </div>
                    )}
                    <p className={`text-4xl font-bold text-blue-600 ${option.popular ? 'mb-2' : 'mb-8'}`}>
                      {option.price}<span className="text-xl font-normal text-gray-600">/{option.period}</span>
                    </p>
                    {option.popular && (
                      <p className="text-green-500 font-semibold">Save $35 (Over 1 month free!)</p>
                    )}
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <ul className="space-y-2">
                    {option.features.map((feature, index) => (
                      <li key={index} className="flex items-center">
                        <Check className="w-5 h-5 mr-2 text-green-500" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </CardContent>
                <CardFooter>
                  <div className={`absolute inset-0 flex items-center justify-center bg-blue-600 bg-opacity-10 transition-opacity duration-300 ${
                    selectedPlan === option.id ? 'opacity-100' : 'opacity-0'
                  }`}>
                  </div>
                </CardFooter>
              </Card>
            ))}
          </div>

          <div className="mt-8">
            <Button
              onClick={handleSubscribe}
              disabled={isLoading}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-blue-600 to-pink-600 text-white hover:opacity-90 transition-opacity focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:opacity-50"
            >
              {isLoading ? 'Processing...' : 'Subscribe Now'}
            </Button>
          </div>
        </motion.div>
      </div>
    </div>
  )
}