import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from "@/components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "@/components/ui/card";
import { Share, SquarePlus, X } from 'lucide-react';

const WelcomePopup = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has seen the popup before
    const hasSeenPopup = localStorage.getItem('hasSeenWelcomePopup');
    
    if (!hasSeenPopup) {
      setIsVisible(true);
      // Mark the popup as seen
      localStorage.setItem('hasSeenWelcomePopup', 'true');
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
          <motion.div
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Card className="w-full max-w-lg relative">
              <Button 
                variant="ghost" 
                size="icon" 
                className="absolute right-2 top-2"
                onClick={handleClose}
              >
                <X className="h-4 w-4" />
              </Button>
              
              <CardHeader>
                <CardTitle className="text-2xl">Welcome to Your Sports Analytics Dashboard!</CardTitle>
                <CardDescription>Get started with powerful sports analytics tools</CardDescription>
              </CardHeader>
              
              <CardContent className="space-y-4">
                <div className="space-y-4">
                    <h3 className="font-semibold mb-2">Download our mobile app for easy access!</h3>
                  <div className="flex items-center gap-2">
                    <span>1.</span>
                    <span>Click Share</span>
                    <Share className="h-4 w-4" />
                  </div>
                  <div className="flex items-center gap-2">
                    <span>2.</span>
                    <span>Add to Home Screen</span>
                    <SquarePlus className="h-4 w-4" />
                  </div>
                </div>
              </CardContent>
              
              <CardFooter>
                <Button 
                  className="w-full bg-blue-600 hover:bg-blue-700 text-white"
                  onClick={handleClose}
                >
                  Get Started
                </Button>
              </CardFooter>
            </Card>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default WelcomePopup;